
import React, {useState,useEffect} from 'react'
import {Card,Row,Container,Col,Image,Nav} from 'react-bootstrap'
import {MenuButton,Select} from './buttons'
import {timeout} from './wait';
import {Url} from './url';
import Axios from 'axios';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import {ShowMsg} from './pop';
import {ShowMenu} from './profilecfg';
import {MENU_TYPE} from './constants/menutypes';
function SideMenu(props){
const e ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,roleid:0,roletype:1};
const c ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,roleid:0,roletype:2};
const w ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,roleid:0,roletype:3};
const [entries,setEntries]= useState(e);
const [changes,setChanges]= useState(c);
const [views,setViews]= useState(w);
const [gotroles, setGotRoles]= useState(false);
const roleid = sessionStorage.getItem('role');

// eslint-disable-next-line react-hooks/exhaustive-deps
const getRolesHandler = async ()=>{
 // await timeout(2000);
  var data={roleid: sessionStorage.getItem('role')};
     var url =Url+'/getroles';
     console.log("geroles url: "+url);
  console.log('Doing Get roles POST here!!! roleID: '+sessionStorage.getItem('role'));
  Axios.post(url,data).then((res)=>{
    console.log('get roles Response received: '+JSON.stringify(res.data));
    if(res.data.msg==='ok'){
        console.log('profile data received OK!!');
        setGotRoles(true);
        if(res.data.roles.length>0){
        setEntries(res.data.roles[0]);
        setChanges(res.data.roles[1]);
        setViews(res.data.roles[2]);
        const roles={e:res.data.roles[0],c:res.data.roles[1],v:res.data.roles[2]};
        sessionStorage.setItem('roles',roles);
      }else{
        clearRoles();
      }

    }
  });
};


useEffect( ()=>{  
  async function fetchdata(){
    console.log("getting roles here!!");
    await getRolesHandler();
    console.log("viewes.user: "+views.user);
  }
  if(!gotroles)   
  fetchdata();
},[getRolesHandler, gotroles]);

const clearRoles =()=>{
  setEntries(e);
  setChanges(c);
  setViews(w);
  console.log("Views.user: "+views.user+ " #w.user default: "+w.user);
}

    return(
    
    <div  className='menu-panel'>
    <CDBSidebar textColor="#fff" backgroundColor="#333" >
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a
            href="/"
            className="text-decoration-none"
            style={{ color: 'inherit' }}
          >
            Dashboard
          </a>
        </CDBSidebarHeader>
          <CDBSidebarContent className="sidebar-content">
        <CDBSidebarMenu className="sidebar">
       
        <Nav.Item>
        <MenuButton  value="Administration" runCode={()=>props.showUi(0)}/>
        </Nav.Item>
        <Nav.Item>
        {ShowMenu(entries,changes,views,MENU_TYPE.MERCHANTS) && <MenuButton value="Merchants" runCode={()=>props.showUi(1)}/>}
        </Nav.Item>
        <Nav.Item>
        {ShowMenu(entries,changes,views,MENU_TYPE.SOFTWAREGRP) && <MenuButton  value="Software Groups" runCode={()=>props.showUi(5)}/>}
        </Nav.Item>
        <Nav.Item>
        {ShowMenu(entries,changes,views,MENU_TYPE.TERMINALS) && <MenuButton value="Terminals" runCode={()=>props.showUi(2)}/>}
        </Nav.Item>
        <Nav.Item>
        {ShowMenu(entries,changes,views,MENU_TYPE.FORCE_UPDATE) && <MenuButton  value="Force Terminal Update" runCode={()=>ShowMsg('Not Yet Implemented!!',false)}/>}
        </Nav.Item>
        <Nav.Item>
        {ShowMenu(entries,changes,views,MENU_TYPE.REPORTS) && <MenuButton value="Reports" runCode={()=>ShowMsg('Not Yet Implemented!!',false)}/>}
        </Nav.Item>
        <Nav.Item>
        {ShowMenu(entries,changes,views,MENU_TYPE.MERCHANTS) && <MenuButton  value="Card Groups" runCode={()=>props.showUi(4)}/>}
        </Nav.Item>
        <Nav.Item>
        {ShowMenu(entries,changes,views,MENU_TYPE.SETTINGS) && <MenuButton value="Settings" runCode={()=>ShowMsg('Not Yet Implemented!!',false)}/>}
        </Nav.Item>
        </CDBSidebarMenu>
          </CDBSidebarContent>
        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: '20px 5px',
            }}
          >
            
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
      </div>
     
    );

}

export default SideMenu;
