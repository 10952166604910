import React from 'react';
import {Form,Button,Card,Row,Container,Col,ListGroup,Dropdown,CardColumns} from 'react-bootstrap'
import Swal from 'sweetalert2'
import Axios from 'axios'
import Flatpickr from "react-flatpickr";
import moment  from 'moment'
import {Url} from './url';
import {ShowMsg} from './pop';
var currencyFormatter = require('currency-formatter');
class OtherConfigurations extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      limits:{
        id:0,
        deposit:0,
        cash:0,
        zipt:0,
        internal:0,
      },
      manuals:{
        id:0,
        enable:false,
        date:null,
        edate:null,
      },
      autos:{
        id:0,
        autotime:null,
        day:0,
      },
      mid:0,
      time:new Date(),

      }
    }
    componentDidUpdate(prevProps, prevState) {
      console.log("Configs component did update!!!!!!!! mid: "+this.props.mid+ ' prevprops.mid: '+prevProps.mid)
      if(this.props.mid != prevProps.mid ){
        this.updateConfigs();
       }else{
         console.log('Configs Criteria failing here!!!')

       }
    }

    clearConfigs=()=>{
      this.setState({
        limits:{
          id:0,
          deposit:0,
          cash:0,
          zipt:0,
          internal:0,
        },
        manuals:{
          id:0,
          enable:false,
          sdate:null,
          edate:null,
        },
        autos:{
          id:0,
          autotime:null,
          day:0,
        },

        });
    }

    updateConfigs=()=>{
      var url =Url+'/getotherconfigs';
      var host = this.state;
      host.mid = this.props.mid;
      if(this.props.mid<=0){
        console.log('othercfg: mid not set!!!')
        //ShowMsg('Please Select or Define Merchant First!!',false);
        return;
      }
      console.log('Doing merch configs read POST here!!! mid: '+this.props.mid);
      Axios.post(url,host).then((res)=>{
        console.log('HostResponse received: '+res.data.msg);
        if(res.data.msg==='ok'){
            console.log('Hst setting data received OK!!');
            if(res.data.data===null){
            this.clearConfigs();
            }else{
              var h = res.data.data;
              console.log("manual settlement is enabled: "+h.manuals.enable);
              this.setState(h);
            }
        }else{
          console.log('Failed to retrieve host settings!!!')
        this.clearConfigs();
        }
      });
    }

    isvalidatedOk =()=>{
      const {time,autos,manuals} = this.state;
  if(time.length===0 || autos.day <=0 ){
    console.log('autotime: '+time+' day: '+autos.day);
      return false;
    }
      console.log('@2autotime: '+autos.autotime+' day: '+autos.day);
      if(manuals.enable){
        if(manuals.sdate.length === 0 || manuals.edate.length === 0){
          console.log('sdate: '+manuals.sdate+' edate: '+manuals.edate);
        return false;
      }
      }
      return true;
    }




    depositHandler=(e)=>{
      var {limits} = this.state;
      limits.deposit = e.target.value;
     this.setState(limits);
     console.log('deposit limit: '+this.state.limits.deposit);
    }
    cashHandler=(e)=>{
      var {limits} = this.state;
      limits.cash = e.target.value;
       this.setState(limits);
    }
    ziptHandler=(e)=>{
      var {limits} = this.state;
      limits.zipt = e.target.value;
       this.setState(limits);
    }
    internalHandler=(e)=>{
      var {limits} = this.state;
      limits.internal = e.target.value;
       this.setState(limits);
    }

    dayHandler=(e)=>{
      var {autos} = this.state;
      autos.day = e.target.value;
       this.setState(autos);
    }

    autotimeHandler=(time)=>{
      var {autos} = this.state;
      var t = moment(time[0]);
      t.utcOffset('+02:00');
      var s = t.format('HH:mm');
      console.log('Time picked: '+s);
      autos.autotime = s;
       this.setState(autos);
    }

    startdateHandler=(date)=>{
      var {manuals} = this.state;
      var m = moment(date[0]);
      m.utcOffset('+02:00');
      var s = m.format('HH:mm');
      console.log('Time picked: '+s);
      manuals.sdate = s;
       this.setState(manuals);
    }

    enddateHandler=(date)=>{
      var {manuals} = this.state;
      var m = moment(date[0]);
      m.utcOffset('+02:00');
      var s = m.format('HH:mm');
      console.log('Time picked: '+s);
      manuals.edate = s;
       this.setState(manuals);
    }

    enableHandler =(e)=>{
        var {manuals} = this.state;
        if(e.target.checked){
          manuals.enable=true;
        }else{
          manuals.enable=false;
        }
        this.setState(manuals);

    }




    saveHandler =()=>{
      if(this.props.mid<=0){
        ShowMsg('Please Select or Define Merchant First!!',false);
        return;
      }
      if(!this.isvalidatedOk()){
        //todo add msgbox
        ShowMsg('Please fill all required fields!!',false);
        return;
      }
      var url =Url+'/addotherconfigs';
      var o = this.state;
      o.mid = this.props.mid;
      if(this.props.oid>0){
        o.id = this.props.oid;
      }
      console.log('Doing merch cards read POST here!!! mid: '+this.props.mid);
      Axios.post(url,o).then((res)=>{
        console.log('Other Response received: '+res.data.msg);
        if(res.data.msg==='ok'){
            console.log('Hst setting saved OK!!');
            ShowMsg(res.data.pop,true);
        }else{
          console.log('Failed to save other settings!!!')
          ShowMsg(res.data.pop,false);
        }
      });
    }

    render(){
      var {date,edate,time,limits,autos,manuals} = this.state;
     return(
       <Card>
       <Card.Body>
       <Form>
       <Row>
        <Col  sm={3}>
        <Card border='info'>
        <Card.Header variant='info'><h6>Limits</h6></Card.Header>
        <Card.Body>
        <Form.Group>
        <Form.Label>Deposit</Form.Label>
        <Form.Control type="number" placeholder="" value={limits.deposit} onChange={this.depositHandler}/>
        </Form.Group>
        <Form.Group>
        <Form.Label>Cash Withdrawal</Form.Label>
        <Form.Control type="number" placeholder="" value={limits.cash} onChange={this.cashHandler}/>
        </Form.Group>
        <Form.Group>
        <Form.Label>Send Money</Form.Label>
        <Form.Control type="number" placeholder="" value={limits.zipt} onChange={this.ziptHandler}/>
        </Form.Group>
        <Form.Group>
        <Form.Label>Internal Transfer</Form.Label>
        <Form.Control type="number" placeholder="" value={limits.internal} onChange={this.internalHandler}/>
        </Form.Group>
        </Card.Body>
        </Card>
        </Col>
        <Col  sm={3}>
        <Card  border='info'>
        <Card.Header><h6>Manual Settlement</h6></Card.Header>
        <Card.Body>
        <Form.Group>
        <Form.Check type="checkbox"  onClick={this.enableHandler} checked={manuals.enable}  label="Settlement Enable"/>
       </Form.Group>
        <Form.Group>
        <Form.Label>Start Time</Form.Label>
        <Flatpickr data-enable-time className={'form-control'} options={{altInput:true,altFormat:'H:i',dateFormat:'H:i',time_24hr:true,enableTime:true,noCalendar:true}} value={manuals.sdate} onChange={(date)=>{this.startdateHandler(date)}}/>
        </Form.Group>
        <Form.Group>
        <Form.Label>End Time</Form.Label>
         <Flatpickr data-enable-time className={'form-control'} options={{time_24hr:true,altInput:true,altFormat:'H:i',dateFormat:'H:i',enableTime:true,noCalendar:true}} value={manuals.edate} onChange={(date)=>{this.enddateHandler(date)}}/>
        </Form.Group>

        </Card.Body>
        </Card>
        </Col>
        <Col  sm={3}>
        <Card  border='info'>
        <Card.Header><h6>Auto Settlement</h6></Card.Header>
        <Card.Body>
        <Form.Group>
       <Form.Label>Week Day</Form.Label>
       <Form.Control as="select" size="sm" onChange={this.dayHandler} value={autos.day} custom >
          <option value='0'>Week Days</option>
          <option value='1'>Monday</option>
          <option value='2'>Tuesday</option>
          <option value='3'>Wednesday</option>
          <option value='4'>Thursday</option>
          <option value='5'>Friday</option>
          <option value='6'>Saturday</option>
          <option value='7'>Sunday</option>
          <option value='8'>Everyday</option>
          <option value='9'>NEVER</option>
       </Form.Control>
       </Form.Group>
        <Form.Group>
        <Form.Label>Time</Form.Label>
         <Flatpickr data-enable-time className={'form-control'} options={{time_24hr:true,altInput:true,altFormat:'H:i',dateFormat:'H:i',enableTime:true,noCalendar:true, defaultDate: "22:00"}} value={autos.autotime} onChange={(time)=>{this.autotimeHandler(time)}}/>
        </Form.Group>
        </Card.Body>
        </Card>
        </Col>
        </Row>
       </Form>
       </Card.Body>
       <Card.Footer variant='info'>
         <Button variant="info" size="sm" className='mt-2' onClick={this.saveHandler} >SAVE SETTINGS</Button>{' '}
       </Card.Footer>
       </Card>
     )
   }

 }
export default OtherConfigurations;
