import React from 'react';
import Merchants from './merchants'
import MerchantDetails from './merchantdetails';
import SoftwareGroups from './softwaregroups';
import Terminals from './terminals';
import Administration from './admin';
import TerminalList from './terminallist';
import Cards from './cards';
//console.log("Before Socket connect here!!!");
//const socket = io(url);
class Screen extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      socket:null,
    }
    }

    async componentDidMount() {
      console.log('Screen Component DID MOUNT!')
   }

    connect = async (soc)=>{
      this.setState({socket:soc});
    }
      render(){
        const idx = this.props.idx;
        switch(idx){
          case 0:
          return(<Administration />)
          case 1:
            return <Merchants/>
          case 2:
            return(<Terminals/>)
          case 3:
            return(<h2>Reports Screen</h2>)
          case 4:
            return(<Cards/>)
          case 5:
            return <SoftwareGroups />
          case 6:
            return <TerminalList rows={10} />
          default:
          return(<h3>Dashboard Screen</h3>)
        }
      }
}

export default Screen;
