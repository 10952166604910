
import React from 'react';

//export const Url ="https://www.tms.dzamtech.co.zw";

//export const Url ="https://afcuatserver.tms.co.zw";

export const Url ="https://afcserver.tms.co.zw";

//export const Url ="http://127.0.0.1:8000";

// export const Url ="http://opentms.ddns.net:8000";
