import React, {useState,useEffect} from 'react';
import {MENU_TYPE} from './constants/menutypes';
import {ROLEOPS} from './constants/constants';
import Axios from 'axios';
import {Url} from './url';
//const e ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,roleid:0,roletype:1};
const e ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,roleid:0,roletype:1};
const c ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,roleid:0,roletype:2};
const w ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,roleid:0,roletype:3};


export const GetRolesHandler = async ()=>{
    const [entries,setEntries]= useState(e);
const [changes,setChanges]= useState(c);
const [views,setViews]= useState(w);
    // await timeout(2000);
     var data={roleid: sessionStorage.getItem('role')};
        var url =Url+'/getroles';
        console.log("geroles url: "+url);
     console.log('Doing Get roles POST here!!! roleID: '+sessionStorage.getItem('role'));
     Axios.post(url,data).then((res)=>{
       console.log('get roles Response received: '+JSON.stringify(res.data));
       if(res.data.msg==='ok'){
           console.log('profile data received OK!!');
           if(res.data.roles.length>0){
           setEntries(res.data.roles[0]);
           setChanges(res.data.roles[1]);
           setViews(res.data.roles[2]);
           const roles ={e:entries,c:changes,v:views};
           sessionStorage.setItem('roles', roles);
         }else{
           //clearRoles();
         }
   
       }
     });
   };

   const clearRoles =()=>{
    // setEntries({});
    // setChanges({});
    // setViews({});
    // console.log("Views.user: "+views.user);
  }

const isentryallowed =(e, menutype)=>{
    switch(menutype){
        case MENU_TYPE.ADMIN:
            return (e.user || e.role);
        case MENU_TYPE.MERCHANTS:
            return (e.merchant);
        case MENU_TYPE.TERMINALS:
            return e.terminal;
        case MENU_TYPE.SOFTWAREGRP:
            return e.soft;
        default:
            return false;
    }
 
}
const iseditallowed =(e, menutype)=>{
    switch(menutype){
        case MENU_TYPE.ADMIN:
            return (e.user || e.role);
        case MENU_TYPE.MERCHANTS:
            return (e.merchant);
        case MENU_TYPE.TERMINALS:
            return e.terminal;
        case MENU_TYPE.SOFTWAREGRP:
            return e.soft;
        default:
            return false;
    }
    
  }

  const isviewallowed =(e, menutype)=>{
    switch(menutype){
        case MENU_TYPE.ADMIN:
            return (e.user || e.role);
        case MENU_TYPE.MERCHANTS:
            return (e.merchant);
        case MENU_TYPE.TERMINALS:
            return e.terminal;
        case MENU_TYPE.SOFTWAREGRP:
            return e.soft;
        default:
            return false;
    }
  }
  export const EventAllowed = (e,o) =>{
    switch(o){
        case ROLEOPS.USER:
            console.log("user status: "+e.user);
        return e.user;
        case ROLEOPS.PROFILE:
            console.log("profile status: "+e.role);
        return e.role;
        case ROLEOPS.MERCHANT:
        return e.merchant;
        case ROLEOPS.TERMINAL:
        return e.terminal;
        case ROLEOPS.SOFT:
        return e.soft;
        default:
        return false;
    }
  }
export const ShowMenu =(e,c,v,menutype)=>{
    return (isentryallowed(e,menutype) || iseditallowed(c,menutype) || isviewallowed(v,menutype)); 
}