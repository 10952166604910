import React from 'react';
import {Tabs,Tab,Form,Button,Card,Row,Container,Col} from 'react-bootstrap'
import Swal from 'sweetalert2'
import Axios from 'axios'
import FileInput from './file';
import {ShowMsg} from './pop';
import {Url} from './url';
class SoftwareGroups extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        rid : 1,
        files: null,
        appmgr:'',
        payment:'',
        installer:'',
        name:''
      }
    }

  isvalidatedOk =()=>{
    if(this.state.appmgr.length===0 || this.state.payment.length===0 || this.state.name.length===0 )
        return false;
        return true;
  }

  handleChange =(files)=>{
      console.log('Files.name: '+files.name);
    files.map((file,i)=>{
        console.log('Selected file #'+(i+1), file.name);
        if(file.name.startsWith('afcbank')){
          this.setState({payment: file.name})
        }else if(file.name.startsWith('appmgr')){
          this.setState({appmgr:file.name})
        }else if(file.name.startsWith('afcinstaller')){
          this.setState({installer:file.name})
        }
        return 0;
    })

    this.setState({files:files})

  }

saveHandler =()=>{
  if(this.state.files===null){
  ShowMsg('Please Upload application files as required!!',false);
  return;
  }
  if(!this.isvalidatedOk()){
    //todo add msgbox
    ShowMsg('Files with wrong formats selected!! \n\n The three files should be named as follows:\n\n 1. appmgr-xxx \n\n 2. afcbank-xxx \n\n 3. afcinstaller-xxx',false);
    return;
  }
  var url =Url+'/addsoftwaregroup';
  var sgrp = this.state;

  console.log('Doing software group POST here!!!');
  Axios.post(url,sgrp).then((res)=>{
    console.log('Sgrp Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('Software Group saved OK!! received note: '+res.data.pop);
        ShowMsg(res.data.pop,true);
    }else{
      console.log('Failed to save software group data!!!')
      ShowMsg(res.data.pop,false);
    }
  });
}

clearHandler =()=>{
  this.setState({files:null,appmgr:'',payment:'',installer:'',name:''});
}

sgrpnameHandler =(e)=>{
  this.setState({name: e.target.value});
}



    render(){
     return(
       <Container  className="pb-4" fluid>
       <Row>

       <Col  sm={5}>
       <Card>
       <Card.Header><span className="App-header shadow-sm">Software Group Entry</span></Card.Header>
       <Card.Body>
       <Form>

       <Form.Group>
       <Form.Label>Software Group Name</Form.Label>
       <Form.Control type="text" placeholder="Software Group Name...." value={this.state.sgrpname} onChange={this.sgrpnameHandler}/>
       </Form.Group>

       <Form.Group>
       <Form.Label>Payment Application files</Form.Label>
       <FileInput
       className={'form-control'}
       name={'apps'}
        multiple={true}
        onDone={this.handleChange}
          />
       </Form.Group>
       <Form.Group>
       <Form.Label>Application Manager</Form.Label>
       <Form.Control type="text" placeholder="App Manager...." value={this.state.appmgr}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Payment</Form.Label>
       <Form.Control type="text" placeholder="Payment...." value={this.state.payment} />
       </Form.Group>
       <Form.Group>
       <Form.Label>Installer</Form.Label>
       <Form.Control type="text" placeholder="Installer...." value={this.state.installer} />
       </Form.Group>

       <Button variant="info" size="sm" onClick={this.saveHandler}>SAVE SOFTWARE GROUP</Button>{' '}
       <Button variant="outline-info" size="sm" onClick={this.clearHandler}>CLEAR</Button>{' '}
       </Form>

       </Card.Body>
       </Card>
       </Col>
       <Col  sm={7}>

       </Col>
       </Row>
       </Container>
     )
   }

  }
  export default SoftwareGroups;
