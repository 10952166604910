import {useState,React} from 'react'
import {Route,Redirect} from 'react-router-dom'

function ProtectedRoute({isAuth:isAuth, component:Component, ...rest}){
  return <Route  exact strict
  {...rest}
  render={(props)=>{
  if(isAuth){
console.log('Logn ok going to Dash');
    return (<Component />);
  }else{
    console.log('ProtectedRoute: login failed!! going back to login here!!');
    return(
    <Redirect to={{pathname: '/auth', state: {from: props.location}}}/>
  );
  }
  }} />

}
export default ProtectedRoute;
