import React from 'react';
import {Tabs,Tab,Form,Button,Card,Row,Container,Col} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import Swal from 'sweetalert2'
import Axios from 'axios'
import FileInput from './file';
import {ShowMsg,Msg} from './pop';
import {Url} from './url';
import {MSGS} from './constants/msg';
import {EventAllowed} from './profilecfg';
import {ROLEOPS,USER_PROF,LoggedInUser} from './constants/constants';
class Terminals extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        stid : '',
        mid: '',
        cardaccepterid:'',
        ptid:'',
        commstype:4,
        model:1,
        id:0,
        sid:0,
        options:[],
        moptions:[],
        mids:[],
        opts:'',
        roles:{}
      }
    }

    async componentDidMount() {
      console.log('terminals Component DID MOUNT!')

     await this.getterminals();
     await this.getmerchants();
     //const r = await GetRolesHandler();
      this.setState({roles: await this.GetRolesHandler()});
   }

   async componentDidUpdate(prevProps, prevState) {
      console.log('Services Component DID UPDATE!')
      //this.setState({mid:this.props.mid});
      if(prevState.options !=null  && this.state.options == null){
          console.log('Previous options are null!! getting values')
          await   this.getterminals();
          await this.getmerchants();
          this.setState({roles: await this.GetRolesHandler()});
      }else{
          console.log('options already received');
      }




   }

   GetRolesHandler = async () =>{
    var data={roleid: sessionStorage.getItem('role')};
    var url =Url+'/getroles';
    console.log("geroles url: "+url);
 console.log('Doing Get roles POST here!!! roleID: '+sessionStorage.getItem('role'));
 Axios.post(url,data).then((res)=>{
   //console.log('get roles Response received: '+JSON.stringify(res.data));
   if(res.data.msg==='ok'){
       console.log('profile data received OK!!');
       const r ={entries:res.data.roles[0],changes:res.data.roles[1],views:res.data.roles[2]};
       this.setState({roles:r});
    
     }else{
       this.clearHandler();
     }

   
    });
   }

   getmerchants1 = async ()=>{
    var url =Url+'/getmerchs';
    var data = {};
   var list;
    console.log('getting merchants POST here!!!');
    Axios.post(url,data).then((res)=>{
      console.log('Response Response received: '+res.data.msg);
      if(res.data.msg==='ok'){
          console.log('Terminals received  OK!! received note: ');
          list = res.data.apps;
 //  console.log("received Branch Datalist: "+JSON.stringify(list));
   let apps=[];

   list.map((app)=>{
       let option={};
       option.value=app.id;
       option.text=app.name;
       apps.push(option);
   }
 )
//  console.log("on Startup: branch options: "+JSON.stringify(merchants));
   this.setState({options:apps});
 }

});
  }

  getmerchants = async ()=>{
    var url =Url+'/getmerchants';
    var data = {};
   var list;
    console.log('getting merchants POST here!!!');
    Axios.post(url,data).then((res)=>{
      console.log('Merchants Response Response received: '+res.data.msg);
      if(res.data.msg==='ok'){
          console.log('Merchants received  OK!! received note: ');
          list = res.data.mids;
   console.log("received merch Datalist: "+JSON.stringify(list));
   let merchs=[];

   list.map((m)=>{
       let option={};
       option.value=m.id;
       option.text=m.name;
       merchs.push(option);
   }
 )
//  console.log("on Startup: branch options: "+JSON.stringify(merchants));
   this.setState({moptions:merchs});
 }

});
  }

   getterminals = async ()=>{
     var url =Url+'/getsoftwaregrps';
     var data = {};
    var list;
     console.log('getting terminals POST here!!!');
     Axios.post(url,data).then((res)=>{
       console.log('Response Response received: '+res.data.msg);
       if(res.data.msg==='ok'){
           console.log('Terminals received  OK!! received note: ');
           list = res.data.apps;
  //  console.log("received Branch Datalist: "+JSON.stringify(list));
    let apps=[];

    list.map((app)=>{
        let option={};
        option.value=app.id;
        option.text=app.name;
        apps.push(option);
    }
  )
//  console.log("on Startup: branch options: "+JSON.stringify(merchants));
    this.setState({options:apps});
  }

});
   }

  isvalidatedOk =()=>{
    console.log(">>this.state: "+JSON.stringify(this.state));
    if(this.state.ptid.length===0 || this.state.stid.length===0 || this.state.mid.length===0 || this.state.commstype===0 || this.state.model===0 || this.state.sid===0)
        return false;
        return true;
  }

  handleChange =(e)=>{

    this.setState({commstype:e.target.value})

  }

  handlemidChange =(e)=>{

    this.setState({mid:e.target.value})

  }

  modelHandler =(e)=>{

    this.setState({model:e.target.value})

  }

  softwaregrpHandler =(e)=>{

    this.setState({sid:e.target.value})

  }

saveHandler =()=>{
 const {roles,id} = this.state;
  if(id===0 && !EventAllowed(roles.entries,ROLEOPS.TERMINAL)){
    ShowMsg(Msg.TERMINAL_INSERT,false);
    return;
  }

  if(id > 0 && !EventAllowed(roles.entries,ROLEOPS.TERMINAL)){
    ShowMsg(Msg.TERMINAL_EDIT,false);
    return;
  }

  if(this.state.id === 0 && !EventAllowed(this.state.roles.entries,ROLEOPS.TERMINAL)){
    ShowMsg(Msg.TERMINAL_INSERT,false)
    return
  }
  if(this.state.id > 0 && !EventAllowed(this.state.roles.changes,ROLEOPS.TERMINAL)){
    ShowMsg(Msg.TERMINAL_EDIT,false)
    return
  }

  if(!this.isvalidatedOk()){
    //todo add msgbox
    ShowMsg('Please fill up all required fields!!',false);
    return;
  }
  var url =Url+'/addterminal';
  var data = this.state;

  console.log('Doing Terminal add POST here!!!');
  Axios.post(url,data).then((res)=>{
    console.log('Response Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('Terminal saved OK!! received note: '+res.data.pop);
        ShowMsg(res.data.pop,true);
    }else{
      console.log('Failed to save terminal data!!!')
      ShowMsg(res.data.pop,false);
    }
  });
}



clearHandler =()=>{
  this.setState({ptid:'',stid:'',mid:'',commstype:4,id:0,model:0,sid:0,roles:{}});
}

findHandler =()=>{
  if(!EventAllowed(this.state.roles.views,ROLEOPS.TERMINAL)){
    ShowMsg(Msg.TERMINAL_FIND,false)
    return
  }
    if(this.state.ptid.length===0 && this.state.stid.length===0 ){
    ShowMsg("Please Enter Tid or Serial Number for record search to proceed!!")
    return;
  }
  var data={ptid:this.state.ptid, stid: this.state.stid};
     var url =Url+'/getterminal';
  console.log('Doing Terminal find POST here!!!');
  Axios.post(url,data).then((res)=>{
    console.log('Terminal Find Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('Terminal Found!! terminal: '+JSON.stringify(res.data.terminal));
        this.setState(res.data.terminal);

        console.log("new Terminalsystem id: "+  this.state.id);
        //ShowMsg(res.data.pop,true);
    }else{
      console.log('Could not find terminal data!!!')
      ShowMsg(res.data.pop,false);
    }
  });

}

ptidHandler =(e)=>{
  this.setState({ptid: e.target.value});
}

stidHandler =(e)=>{
  this.setState({stid: e.target.value});
}

midHandler =(e)=>{
  this.setState({cardaccepterid: e.target.value});
}

activateHandler =()=>{
  const roleid = sessionStorage.getItem('role');
  console.log("loggedin User id: "+roleid);
  
  var data={ptid:this.state.ptid, stid: this.state.stid};
     var url =Url+'/exportterminal';
  console.log('Doing Terminal export POST here!!!');
  Axios.post(url,data).then((res)=>{
    console.log('Terminal Find Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('Terminal Export OK!!');
        ShowMsg(res.data.pop,true);
    }else{
      console.log('Terminal Export failed!!!')
      ShowMsg(res.data.pop,false);
    }
  });
}

    render(){

     return(
        <Container  className="pb-4" fluid>
       <Row>
       <Col  sm={6}>
       <Card>
       <Card.Header><span className="App-header shadow-sm">Terminal Configurations</span></Card.Header>
       <Card.Body>
       <Form>
       <Form.Group>
      <Form.Label>Software Group</Form.Label>
      <Form.Control as="select" size="sm" onChange={this.softwaregrpHandler} value={this.state.sid} custom >
         <option value='0'>Software Groups</option>
         {
           this.state.options.map((opt)=>(
             <option value={opt.value}>{opt.text}</option>
           ))
         }

      </Form.Control>
      </Form.Group>
       <Form.Group>
       <Form.Label>Terminal Serial Number</Form.Label>
       <Form.Control type="text" placeholder="Serial Number...." value={this.state.ptid} onChange={this.ptidHandler}/>
       </Form.Group>
        <Form.Group>
       <Form.Label>Terminal Model</Form.Label>
       <Form.Control as="select" size="sm" onChange={this.modelHandler} value={this.state.model} custom >
         <option value='0'>Terminal Model</option> 
          <option selected value='1'>CS10</option>
         {/* <option value='2'>PAX S920</option>
          <option value='3'>PAX Q80</option>
          <option value='4'>PAX A60</option>
          <option value='5'>PAX A80</option>*/}
       </Form.Control>
       </Form.Group>
       <Form.Label>Merchant Group Name</Form.Label>
       <Form.Control as="select" size="sm" onChange={this.handlemidChange} value={this.state.mid} custom >
         <option value='0'>Merchant Groups</option>
         {
           this.state.moptions.map((opt)=>(
             <option value={opt.value}>{opt.text}</option>
           ))
         }
       </Form.Control>
       <Form.Group>
       <Form.Label>Card Acceptor ID</Form.Label>
       <Form.Control type="text" placeholder="Card Acceptor ID...." value={this.state.cardaccepterid} onChange={this.midHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Terminal ID</Form.Label>
       <Form.Control type="text" placeholder="Terminal ID...." value={this.state.stid} onChange={this.stidHandler}/>
       </Form.Group>  
       <Form.Group>
       <Form.Label>Comms Type</Form.Label>
       <Form.Control as="select" size="sm" onChange={this.handleChange} value={this.state.commstype} custom >
         <option value='0'>Comms Type</option>
          <option value='1'>GPRS</option>
          <option value='2'>WIFI</option>
          <option value='3'>ETHERNET</option>
          <option selected value='4'>GPRS,WIFI</option>
          <option value='5'>WIFI,ETHERNET</option>
          <option value='6'>GPRS,WIFI,ETHERNET</option>
       </Form.Control>
       </Form.Group>
       </Form>
       </Card.Body>
       <Card.Footer className="text-muted">
       <Button variant="info" size="sm" onClick={this.saveHandler}>SAVE TERMINAL</Button>{' '}
       <Button variant="outline-info" size="sm" onClick={this.findHandler}>FIND</Button>{' '}
       <Button variant="outline-info" size="sm" onClick={this.clearHandler}>CLEAR</Button>{' '}
       <Button variant="outline-info" size="sm" onClick={this.activateHandler}>EXPORT TERMINAL</Button>{' '}
       </Card.Footer>
       </Card>
       </Col>
       <Col sm={6}>
       </Col>
       </Row>
       </Container>
     )
   }

  }
  export default Terminals;
