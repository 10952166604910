import {useState,useEffect} from 'react';
import React from 'react';
import {Button,Form,Card,Row,ListGroup,Container,Col} from 'react-bootstrap';
import {ConfirmMsg, ShowMsg} from './pop';
import Axios from 'axios';
import {Url} from './url';
import {timeout} from './wait';
import {showSpinDiag} from './spin';
import { Redirect } from "react-router";

function ChangePassword(...props){

    const [oldpass,setOldPass] = useState('');
    const [newpass,setNewPass]= useState('');
    const [conpass,setConPass] = useState('');
    const [forcelogout,setForceLogout] = useState(false);

    const passwordHandler = (e)=>{
        const val = e.target.value;
        switch(e.target.id){
            case 'op':
                setOldPass(val);
                break;
            case 'np':
                setNewPass(val);
                break;
            case 'cp':
                setConPass(val);
                break;
                default:
                    break;

        }
    }
    const changePassword =()=>{
     if(oldpass.length===0 || newpass.length===0 || conpass.length===0){
         ShowMsg("Please Enter The Passwords, As Expected!!",false);
         return;
     }

     if(newpass !== conpass){
         ShowMsg("New Password Mismatch!!",false);
         return;
     }
     const id = sessionStorage.getItem('ndiani');
     var url =Url+'/changeuserpass';
     const data ={id:id,pass:newpass,opass:oldpass};
     Axios.post(url,data).then((res)=>{
         if(res.data.msg==='ok'){
             ConfirmMsg('Password Change Success','Would you want to logout of the system, for relogin?',logout);
         }else{
             ShowMsg(res.data.pop,false);
         }
     })
    }

    const logout = ()=>{
        sessionStorage.clear();
        setForceLogout(true);
       
    }

    const clearPasswords =()=>{
        setConPass('');
        setNewPass('');
        setOldPass('');
    }

    const btnHandler = (e)=>{
        switch(e.target.id){
          case 'btnchangepassword':
          changePassword();
          break;
          case 'btnclear':
          clearPasswords();
          break;
          default:
        }
    }
   if(forcelogout){
       return  <Redirect to={{pathname: '/'}}/>
   }
    return(
        <Container className="mt-2" fluid>
    <Row className="pt-2 ">
    <Col  sm={8}>
    <Card className=" pb-1 pt-2">
    <Card.Body>
    <Form>
    <Form.Group>
    <Form.Label>Current Password</Form.Label>
    <Form.Control id='op' autoComplete={'nope3'} type="password" placeholder="Current Password..." value={oldpass} onChange={passwordHandler}/>
    </Form.Group>
    <Form.Group>
    <Form.Label>New Password</Form.Label>
    <Form.Control id='np' autoComplete={'nope3'} type="password" placeholder="New Password..." value={newpass} onChange={passwordHandler}/>
    </Form.Group>
    <Form.Group>
    <Form.Label>Confirm Password</Form.Label>
    <Form.Control id='cp' autoComplete={'nope3'} type="password" placeholder="Confirm Password..." value={conpass} onChange={passwordHandler}/>
    </Form.Group>
    </Form>
    </Card.Body>
    <Card.Footer>
    <Button id='btnchangepassword' variant="info" onClick={btnHandler} size="sm" >CHANGE PASSWORD</Button>{' '}
      <Button id='btnclear' variant="outline-info" size="sm" onClick={btnHandler} >CLEAR</Button>{' '}
    </Card.Footer>
    </Card>
    </Col>
    </Row>
    </Container>
    )


}
export default ChangePassword