import React from 'react';
import {Tabs,Tab,Form,Button,Card,Row,Container,Col,ListGroup} from 'react-bootstrap'
import Swal from 'sweetalert2'
import Axios from 'axios'
import {Url} from './url';
import {ShowMsg,Msg} from './pop';
import {showSpinDiag} from './spin';
import {MSGS} from './constants/msg';
import {EventAllowed} from './profilecfg';
import {ROLEOPS,USER_PROF,LoggedInUser} from './constants/constants';
class MerchantDetails extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        id:0,
        mid : '',
        mname:'',
        maddr1:'',
        maddr2:'',
        maddr3:'',
        maddr4:'',
        currencycode:0,
        currencynumber:0,
        countrynumber:0,
        currs:[['USD','ZUR','ZWL','GHS','GBP'],[840,710,932,936,826],[840,710,716,288,826]],
        middisable:false,
        randid: 0,
        hideresults:true,
        merchList:[],
        isspin:false,
        roles:{},
      }
    }

    async componentDidMount() {
      console.log('merch details Component DID MOUNT!')
      this.setState({roles: await this.GetRolesHandler()});
   }

   async componentDidUpdate(prevProps, prevState) {
      console.log('Merch details Component DID UPDATE!')
      //this.setState({mid:this.props.mid});
      if(prevState.options !=null  && this.state.options == null){
          console.log('Previous options are null!! getting values')
          this.setState({roles: await this.GetRolesHandler()});
      }else{
          console.log('options already received');
      }




   }


   GetRolesHandler = async () =>{
    var data={roleid: sessionStorage.getItem('role')};
    var url =Url+'/getroles';
    console.log("geroles url: "+url);
 console.log('Doing Get roles POST here!!! roleID: '+sessionStorage.getItem('role'));
 Axios.post(url,data).then((res)=>{
   console.log('get roles Response received: '+JSON.stringify(res.data));
   if(res.data.msg==='ok'){
       console.log('profile data received OK!!');
       const r ={entries:res.data.roles[0],changes:res.data.roles[1],views:res.data.roles[2]};
       this.setState({roles:r});
    
     }else{
       this.clearHandler();
     }

   
    });
   }


    midHandler =(e)=>{
      this.setState({mid:e.target.value})
    }

    merchantnameHandler =(e)=>{
      this.setState({mname:e.target.value})
    }
    maddr1Handler =(e)=>{
      this.setState({maddr1:e.target.value})
    }
    maddr2Handler =(e)=>{
      this.setState({maddr2:e.target.value})
    }
    maddr3Handler =(e)=>{
      this.setState({maddr3:e.target.value})
    }
    maddr4Handler =(e)=>{
      this.setState({maddr4:e.target.value})
    }

    merchResultHandler = (e)=>{
        e.preventDefault();
      const idx = e.target.value;
      console.log('Selected Index: '+ idx);
      if(this.state.merchList.length > idx){
        const m = this.state.merchList[idx];
       this.setState({middisable:true});
           this.setState({id:m.id});
           this.setState({mid:m.mid});
           this.setState({mname:m.name});
           this.setState({maddr1:m.addr1});
           this.setState({maddr2:m.addr2});
           this.setState({maddr3:m.addr3});
           this.setState({maddr4:m.addr4});
          this.setState({currencycode:m.currencycode})
          this.setState({currencynumber:m.currencynumber})
          this.setState({countrynumber:m.countrycode},()=>{
            this.findHandler();
          })

       }
    }

    generateRandom =()=>{
      const min = 1;
        const max = 100;
        const random = min + (Math.random() * (max - min));
        this.setState({randid: random })
    }

    findHandler=()=>{
      var url =Url+'/findmerch'
      if(!EventAllowed(this.state.roles.views,ROLEOPS.MERCHANT)){
        ShowMsg(Msg.MERCHANT_FIND,false)
        return
      }
      console.log('Doing find merch POST here!!!');
      const {mid,mname}=this.state;
      console.log('MDetails current mid: '+mid);
      if(mid==='' && mname===''){
        ShowMsg('Please Enter search data first!!',false);
        return;
      }
      if(this.state.id>0){
          this.props.getmid(this.state.id);
      }
      Axios.post(url,{
        mid: this.state.mid,
        name:this.state.mname,
      }).then((res)=>{
        console.log('Find Response received: '+res.data.msg);
        if(res.data.msg==='ok' && res.data.data !== null){
          this.generateRandom();
            console.log('MID received: '+res.data.data.mid);
              this.props.getmid(res.data.data.id);
              if(res.data.services!=null){
              console.log("Balance Service: "+res.data.services.balance)
              var s= res.data.services;
                s.random = this.state.randid;
              this.props.getsdata(s);
            }
            console.log("Currency code: "+res.data.data.currencycode);
            sessionStorage.setItem('cmid',res.data.data.id);
            this.setState({middisable:true});
              this.setState({id:res.data.data.id});
              this.setState({mid:res.data.data.mid});
              this.setState({mname:res.data.data.name});
              this.setState({maddr1:res.data.data.addr1});
              this.setState({maddr2:res.data.data.addr2});
              this.setState({maddr3:res.data.data.addr3});
              this.setState({maddr4:res.data.data.addr4});
             this.setState({currencycode:res.data.data.currencycode})
             this.setState({currencynumber:res.data.data.currencynumber})
             this.setState({countrynumber:res.data.data.countrycode})
        }else{
          ShowMsg('Merchant Record not found!!',false);
          this.props.getmid(0);
          this.props.getsdata(null);
          this.clearHandler();
        }
      });
    }

    findallHandler=()=>{
      const {isspin}=this.state;
      if(!EventAllowed(this.state.roles.views,ROLEOPS.MERCHANT)){
        ShowMsg(Msg.MERCHANT_FIND,false)
        return
      }
      console.log('merchant: '+JSON.stringify(this.state));
       console.log('merchant id: '+this.state.mid+' name: '+this.state.mname);
       var data={mid: this.state.mid, name: this.state.mname};
          var url =Url+'/findallmerch';
       console.log('Doing Get users POST here!!!');
        this.setState({isspin:true});
       Axios.post(url,data).then((res)=>{

         console.log('User Find Response received: '+res.data.msg);

         this.setState({isspin:false});
         if(res.data.msg==='ok'){
             console.log('User list data received OK!!');
             if(res.data.mlist.length>0){
               this.setState({merchList:res.data.mlist});
                this.setState({hideresults:false});
             }

         }else{
           this.setState({merchList:[]});
           console.log('merch find failed!!!')
           ShowMsg(res.data.pop,false);
         }
       });
    }

    validator=()=>{
      const {mid,mname,maddr1,maddr2,currencycode} = this.state;
      if(mid==='' || mname==='' || maddr1==='' || currencycode===0)
      return false;
      return true;
    }


    saveHandler=()=>{
      var url =Url+'/addmerch'
      const {roles,id} = this.state;
      if(id===0 && !EventAllowed(roles.entries,ROLEOPS.MERCHANT)){
        ShowMsg(Msg.TERMINAL_INSERT,false);
        return;
      }
    
      if(id > 0 && !EventAllowed(roles.entries,ROLEOPS.MERCHANT)){
        ShowMsg(Msg.MERCHANT_EDIT,false);
        return;
      }
    
      if(this.state.id === 0 && !EventAllowed(this.state.roles.entries,ROLEOPS.MERCHANT)){
        ShowMsg(Msg.MERCHANT_INSERT,false)
        return
      }
      if(this.state.id > 0 && !EventAllowed(this.state.roles.changes,ROLEOPS.MERCHANT)){
        ShowMsg(Msg.MERCHANT_EDIT,false)
        return
      }
      if(!this.validator()){
        ShowMsg('Please enter all fields!!',false);
        return;
      }
      console.log('Doing Sign Up POST here!!!');
      Axios.post(url,{
        id: this.state.id,
        mid: this.state.mid,
        name:this.state.mname,
        addr1:this.state.maddr1,
        addr2:this.state.maddr2,
        addr3:this.state.maddr3,
        addr4:this.state.maddr4,
        addr5:this.state.maddr5,
        currencycode:this.state.currencycode,
        currencynumber:this.state.currencynumber,
        countrycode:this.state.countrynumber,
        sid:1,
      }).then((res)=>{
        console.log('Response received: '+res.data.msg);
          console.log('MID received: '+res.data.id);
        if(res.data.msg==='ok'){
          sessionStorage.setItem('cmid',res.data.id);
          this.props.getmid(res.data.id);
          var id = this.state.id;

          if(id>0)
          ShowMsg('Details Update was Successful!!',true);
          else
          ShowMsg('Registration was Successful!!',true);
          this.setState({id:res.data.id});
        }else{
         ShowMsg(res.data.pop,false);
        }
      });
    }
    clearHandler=()=>{
        this.props.getsdata(null);
      this.props.getmid(0);
      this.setState({id:0});
      this.setState({mid:''});
      this.setState({mname:''});
      this.setState({maddr1:''});
      this.setState({maddr2:''});
      this.setState({maddr3:''});
      this.setState({maddr4:''});
      this.setState({currencycode:0});
      this.setState({currencynumber:0});
      this.setState({countrynumber:0});
      this.setState({middisable:false});
      this.setState({hideresults:true});
      this.setState({merchList:[]});
      this.setState({isspin:false});
    }
    activateHandler=()=>{
      var url =Url+'/exportmerchant'
      console.log('Doing Merchant Export POST here!!!');
      if(this.state.mid===''){
        ShowMsg('Please Specify Merchant Data to Export!!',false);
        return;
      }
      Axios.post(url,{
        mid: this.state.mid,
      }).then((res)=>{
        console.log('Response received: '+res.data.msg);
          console.log('MSG received: '+res.data.pop);
        if(res.data.msg==='ok'){
          ShowMsg(res.data.pop,true);
        }else{
          ShowMsg(res.data.pop,false);
        }
      });
    }

    currencycodeHandler =(e)=>{
        this.setState({currencycode:e.target.value});
        console.log("currency code value: "+this.state.currencycode);
        var pos = e.target.value;
        console.log("currency code value: "+pos);
        if(pos>0){
        pos -=1;
        const dat ={currencynumber: this.state.currs[1][pos],countrynumber: this.state.currs[2][pos]};
        this.setState(dat);
      }

    }


      render(){
        const {isspin,hideresults}= this.state;
       return(
         <Card>
         <Card.Body>
         <Form>
         <Row>
         <Col  sm={3}>
         <Form.Group>
         <Form.Label>Merchant ID</Form.Label>
         <Form.Control type="text" disabled={this.state.middisable} placeholder="New Merchant ID...." value={this.state.mid} onChange={this.midHandler}/>
         </Form.Group>
         <Form.Group>
         <Form.Label>Merchant Name</Form.Label>
         <Form.Control type="text" placeholder="Merchant Name...." value={this.state.mname} onChange={this.merchantnameHandler}/>
         </Form.Group>
         <Form.Group>
         <Form.Label>Address Line1</Form.Label>
         <Form.Control type="text" placeholder="Address1...." value={this.state.maddr1} onChange={this.maddr1Handler}/>
         </Form.Group>
          {showSpinDiag(isspin,'Searching record(s)')}
         </Col>
         <Col  sm={3}>
         <Form.Group>
         <Form.Label>Address Line2</Form.Label>
         <Form.Control type="text" placeholder="Address2...." value={this.state.maddr2} onChange={this.maddr2Handler}/>
         </Form.Group>
         <Form.Group>
         <Form.Label>Address Line3</Form.Label>
         <Form.Control type="text" placeholder="Address3...." value={this.state.maddr3} onChange={this.maddr3Handler}/>
         </Form.Group>
         <Form.Group>
         <Form.Label>Adrress Line4</Form.Label>
         <Form.Control type="text" placeholder="Address4...." value={this.state.maddr4} onChange={this.maddr4Handler}/>
         </Form.Group>
         </Col>
         <Col  sm={3}>
         <Form.Group>
         <Form.Label>Currency Code</Form.Label>
         <Form.Control as="select" size="sm" onChange={this.currencycodeHandler} value={this.state.currencycode} custom >
            <option value='0'>Currency Code</option>
            <option value='1'>USD</option>
            <option value='2'>ZUR</option>
            <option value='3'>ZWL</option>
            <option value='4'>GHS</option>
            <option value='5'>GBP</option>
         </Form.Control>
         </Form.Group>
         <Form.Group>
         <Form.Label>Currency Number</Form.Label>
         <Form.Control type="text" placeholder="Currency Number...." value={this.state.currencynumber} onChange={this.currencynumberHandler}/>
         </Form.Group>
         <Form.Group>
         <Form.Label>Country Number</Form.Label>
         <Form.Control type="text" placeholder="Country Number...." value={this.state.countrynumber} onChange={this.countrynumberHandler}/>
         </Form.Group>
         </Col>
         <Col  sm={3} hidden={hideresults} >
         <Card className=" pb-1 pt-2 sform">
         <Card.Body>
         <React.Fragment>
         <ListGroup>
         <ListGroup.Item  variant="dark"><h6>Merchant Search Results</h6></ListGroup.Item>
         {this.state.merchList.map((item, i)=>(
           <ListGroup.Item action value={i} onClick={(e)=>this.merchResultHandler(e)} variant='info'>
           {item.name}
           </ListGroup.Item>
         ))}
         </ListGroup>
         </React.Fragment>
         </Card.Body>
         </Card>
         </Col>
         </Row>
         <Button variant="info" size="sm" onClick={this.saveHandler}>SAVE</Button>{' '}
         <Button variant="outline-info" size="sm" onClick={this.clearHandler}>CLEAR</Button>{' '}
           <Button variant="outline-info" size="sm" onClick={this.findHandler}>FIND</Button>{' '}
           <Button variant="outline-info" size="sm" onClick={this.findallHandler}>FIND ALL</Button>{' '}
           <Button variant="outline-info" size="sm" onClick={this.activateHandler}>EXPORT MERCHANT</Button>
         </Form>

         </Card.Body>
         </Card>
       );
      }
  }
export default MerchantDetails;
