import React from 'react';
import Swal from 'sweetalert2'
export const Msg ={
  USER_INSERT:"You are not allowed to add new user!!",
  USER_EDIT: "You are not allowed to modify user details!!",
  USER_DELETE:"You are not allowed to delete users!!",
  USER_FIND: "You do not have priviledges to search for system users!!",
  ROLE_FIND:"You are not allowed to view profile details!!",
  ROLE_EDIT: "You are not allowed to modify profile details!!",
  TERMINAL_INSERT:"You are not allowed to add new terminal!!",
  TERMINAL_FIND:"You are not allowed to view terminal details!!",
  TERMINAL_EDIT: "You are not allowed to modify terminal details!!",
  MERCHANT_INSERT:"You are not allowed to add new terminal!!",
  MERCHANT_FIND:"You are not allowed to view merchant details!!",
  MERCHANT_EDIT: "You are not allowed to modify merchant details!!",
  SOFTWARE_GRP_EDIT: "You are not allowed to modify this option!!",
}
export const ShowMsg =(msg,isOk)=>{
  Swal.fire(
    {
      title:'Open TMS',
      text: msg,
      icon:isOk?'success':'error',
    confirmButtonText:'OK'
    }
  )
}
const BootStrapSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-info',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling:false
})
export const ConfirmMsg =(title,msg,fun)=>{
  BootStrapSwal.fire({
  title: title,
  text: msg,
  icon: 'info',
  showCancelButton:true,
  confirmButtonText:"CONFRIM ",
  cancelButtonText: 'DECLINE ',
  reverseButtons:true
  }).then((result)=>{
    if(result.isConfirmed){
      fun();
    }
  })
}
