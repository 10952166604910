import {useState,useEffect} from 'react'
import React from 'react';
import {Button,Form,Card,Row,ListGroup,Container,Col} from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {ShowMsg,Msg} from './pop';
import Axios from 'axios';
import {Url} from './url';
import {showSpinDiag} from './spin';
import {ROLEOPS} from './constants/constants';
import {EventAllowed} from './profilecfg';
function Users ({mid:mid,...props}){

const [id,setId] = useState(mid);
const [user,setUser] = useState({id:0,fname:'',lname:'',userid:'',userpass:'',mobile:'',email:'',locked:false,roleid:0,rid:1})
const [disable,setDisable] = useState({userid:false,email:false});
const [userList,setUserList]= useState([]);
const [hideresults,setHideResults] = useState(true);
const [isSpin,setSpin] = useState(false);
const e ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,roleid:0,roletype:1};
const c ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,roleid:0,roletype:2};
const w ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,roleid:0,roletype:3};
const [entries,setEntries]= useState(e);
const [changes,setChanges]= useState(c);
const [views,setViews]= useState(w);
const [gotroles, setGotRoles]= useState(false);
const rid =parseInt(sessionStorage.getItem('role'));

const getRolesHandler = async ()=>{
  // await timeout(2000);
   var data={roleid: sessionStorage.getItem('role')};
      var url =Url+'/getroles';
      console.log("geroles url: "+url);
   console.log('Doing Get roles POST here!!! roleID: '+sessionStorage.getItem('role'));
   Axios.post(url,data).then((res)=>{
     console.log('get roles Response received: '+JSON.stringify(res.data));
     if(res.data.msg==='ok'){
         console.log('profile data received OK!!');
         setGotRoles(true);
         if(res.data.roles.length>0){
         setEntries(res.data.roles[0]);
         setChanges(res.data.roles[1]);
         setViews(res.data.roles[2]);
       }else{
         clearRoles();
       }
 
     }
   });
 };

 const clearRoles =()=>{
  setEntries({});
  setChanges({});
  setViews({});
  console.log("Views.user: "+views.user);
}


const userResultHandler = (e)=>{
  const idx = e.target.value;
  console.log('Selected Index: '+ idx);
  if(userList.length > idx){
     setUser(userList[idx]);
      setDisable({userid:true,email:true});
   }
}
useEffect(()=>{  
  async function fetchdata(){
    console.log("getting roles here!!");
    await getRolesHandler();
    console.log("entries.user: "+entries.user);
  }
  if(!gotroles)   
  fetchdata();
},[getRolesHandler, gotroles]);
const checkFind =()=>{
  console.log('userid: '+user.userid);
  if(user.email ==='' && user.userid ==='')
  return false;

  return true;
}

const validateUser =()=>{
  console.log('userid: '+user.userid);
  if(user.email.length === 0 && user.userid.length === 0 && user.fname.length === 0 && user.lname.length === 0 && user.userpass.length === 0 && user.mobile.length === 0 && user.email.length === 0 && user.roleid === 0)
  return false;
  return true;
}


const saveUser = ()=>{
  if(user.id === 0 && !EventAllowed(entries,ROLEOPS.USER)){
    ShowMsg(Msg.USER_INSERT,false)
    return
  } 
  if(user.id > 0 && !EventAllowed(changes,ROLEOPS.USER)){
    ShowMsg(Msg.USER_EDIT,false)
      return;
  }


  if(!validateUser()){
    ShowMsg('Please Fill Up All Fields!!');
    return;
  }

  console.log('Doing Get user POST here!!!');
  var url =Url+'/reg';
  Axios.post(url,user).then((res)=>{
    console.log('User Find Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('User saved OK!!');
        setGotRoles(false);
        ShowMsg(res.data.pop,true);
    }else{
      console.log('User find failed!!!');
      ShowMsg(res.data.pop,false);
    }
  });

}

const getAllUsers = ()=>{
  /*if(!checkFind()){
  ShowMsg('Please Input Search Data!!' ,false);
  return;
}*/
if(!EventAllowed(views,ROLEOPS.USER)){
  setSpin(false);
  ShowMsg(Msg.USER_FIND,false)
  return
}
  
 console.log('user: '+JSON.stringify(user));
  console.log('User id: '+user.userid+' email: '+user.email);
  var data={userid: user.userid, email: user.email,roleid:sessionStorage.getItem('role')};
  console.log("getusers data to send: "+JSON.stringify(data));
     var url =Url+'/getusers';
  console.log('Doing Get users POST here!!!');
  Axios.post(url,data).then((res)=>{
    setSpin(false);
    console.log('User Find Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('User list data received OK!!');
        setGotRoles(false);
        setUserList(res.data.users);
          setHideResults(false);


    }else{
      setUserList(res.data.users);
      console.log('User find failed!!!')
      ShowMsg(res.data.pop,false);
    }
  });
}

const getUser =()=>{
  if(!EventAllowed(views,ROLEOPS.USER)){
    ShowMsg(Msg.USER_FIND,false);
    return;
  }
  
  if(!checkFind()){
  ShowMsg('Please Input Search Data!!' ,false);
  return;
}
 console.log('user: '+JSON.stringify(user));
  console.log('User id: '+user.userid+' email: '+user.email);
  var data={userid: user.userid, email: user.email,roleid:sessionStorage.getItem('role')};
     var url =Url+'/getuser';
  console.log('Doing Get user POST here!!!');
      setSpin(true);
  Axios.post(url,data).then((res)=>{
    console.log('User Find Response received: '+res.data.msg);
    setSpin(false);
    if(res.data.msg==='ok'){
        console.log('User data received OK!!');
        setGotRoles(false);
        setUser(res.data.user);
        setDisable({userid:true,email:true});
    }else{
      console.log('User find failed!!!')
      ShowMsg(res.data.pop,false);
    }
  });
}

const clearUser =()=>{
  setSpin(false);
  setUser({id:0,fname:'',lname:'',userid:'',userpass:'',mobile:'',email:'',locked:false,roleid:0,rid:1});
  setDisable({userid:false,email:false});
  setUserList([]);
  setHideResults(true);
}

const btnHandler = (e)=>{
  switch(e.target.id){
    case 'btnsave':
    saveUser();
    break;
    case 'btnclear':
    clearUser();
    break;
    case 'btnfind':
    getUser();
    break;
    case 'btnfall':
    setSpin(true);
    getAllUsers();
    break;
  }
}

const fieldHandler = (e)=>{
  const val = e.target.value;
  switch(e.target.id){
    case 'fn':
    setUser({...user,fname:val});
    break;
    case 'ln':
    setUser({...user,lname:val});
    break;
    case 'uname':
    setUser({...user,userid:val});
    console.log('userid: '+user.userid);
    break;
    case 'pass':
    setUser({...user,userpass:val});
    break;
    case 'mob':
    setUser({...user,mobile:val});
    break;
    case 'em':
    setUser({...user,email:val});
      console.log('email: '+user.email);
    break;
    case 'cborole':
    setUser({...user,roleid:val});
    break;
    case 'chklock':
    if(e.target.checked){
      setUser({...user,locked:true});
    }else{
      setUser({...user,locked:false});
    }
    break;
  }
}

  return(
    <Container className="mt-2" fluid>
    <Row className="pt-2 ">
    <Col  sm={8}>
    <Card className=" pb-1 pt-2">
    <Card.Body>
    <Form>
    <Row>
    <Col sm={6}>
    <Form.Group>
    <Form.Label>First Name</Form.Label>
    <Form.Control id='fn' autoComplete={'nope3'} type="text" placeholder="First Name..." value={user.fname} onChange={fieldHandler}/>
    </Form.Group>
    <Form.Group>
    <Form.Label>Last Name</Form.Label>
    <Form.Control id='ln' autoComplete={'nope2'} type="text" placeholder="Last Name..." value={user.lname} onChange={fieldHandler}/>
    </Form.Group>
    <Form.Group>
    <Form.Label>Username</Form.Label>
    <Form.Control id='uname' autoComplete={'nope1'} disabled={disable.userid?'disabled':null} type="text" placeholder="Username..." value={user.userid} onChange={fieldHandler}/>
    </Form.Group>
    <Form.Group>
    <Form.Label>User Password</Form.Label>
    <Form.Control id='pass' type="password"  placeholder="Password..." value={user.userpass} onChange={fieldHandler}/>
    </Form.Group>
       {showSpinDiag(isSpin,'Searching record(s)')}
    </Col>
    <Col sm={6}>
    <Form.Group>
    <Form.Label>Mobile Number</Form.Label>
    <Form.Control id='mob' autoComplete={'nope'} type="text" placeholder="mobile number..." value={user.mobile} onChange={fieldHandler} />
    </Form.Group>
    <Form.Group>
    <Form.Group>
    <Form.Label>Email</Form.Label>
    <Form.Control id='em' autoComplete={'off'} disabled={disable.email?'disabled':null} type="email" placeholder="email..." value={user.email} onChange={fieldHandler} />
    </Form.Group>
   <Form.Label>User Profile</Form.Label>
   <Form.Control id='cborole' as="select" size="sm" onChange={fieldHandler} value={user.roleid} custom >
      <option value='0'>User Profile</option>
      <option value='1'>Clerck</option>
      {(rid>= 3) ? <option value='2'>Supervisor</option>:''}
      {(rid >= 4) ? <option value='3'>Manager</option>:''}
      {(rid >= 5) ? <option value='4'>Administrator</option>:''}
      {(rid >= 5)? <option value='5'>Prime</option>:''}
   </Form.Control>
   </Form.Group>
   <Form.Group>
   <Form.Check id='chklock' type="checkbox"  onClick={fieldHandler} checked={user.locked}  label="User Locked"/>
   </Form.Group>

    </Col>
    </Row>
    </Form>
    </Card.Body>
    <Card.Footer>
      <Button id='btnsave' variant="info" onClick={btnHandler} size="sm" >SAVE USER</Button>{' '}
      <Button id='btnclear' variant="outline-info" size="sm" onClick={btnHandler} >CLEAR</Button>{' '}
      <Button id='btnfind' variant="outline-info" size="sm" onClick={btnHandler}>FIND</Button>{' '}
      <Button id='btnfall' variant="outline-info" size="sm" onClick={btnHandler}>FIND ALL</Button>{' '}
    </Card.Footer>
    </Card>
    </Col>
    <Col  sm={4} hidden={hideresults}>
    <Card className=" pb-1 pt-2">
    <Card.Body>
    <React.Fragment>
    <ListGroup>
    <ListGroup.Item  variant="dark"><h6>Search Results</h6></ListGroup.Item>
    {userList.map((item, i)=>(
      <ListGroup.Item action value={i} onClick={(e)=>userResultHandler(e)} variant='info'>
      {item.userid}
      </ListGroup.Item>
    ))}
    </ListGroup>
    </React.Fragment>
    </Card.Body>
    </Card>
    </Col>
    </Row>
    </Container>
  )

}

export default Users;
