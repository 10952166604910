import React from 'react';
import {Tabs,Tab,Form,Button,Card,Row,Container,Col,ListGroup,Dropdown,CardColumns} from 'react-bootstrap'
import Swal from 'sweetalert2'
import Axios from 'axios'
import {Url} from './url';
import {ShowMsg} from './pop';
class PromoConfigurations extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        mid : this.props.mid,
        promo1:'',
        promo2:'',
        promo3:'',
        promo4:'',
        promo5:'',


      }
    }

    componentDidUpdate(prevProps, prevState) {
      console.log("Promotions component did update!!!!!!!! mid: "+this.props.mid+ ' prevprops.mid: '+prevProps.mid)
      if(this.props.mid != prevProps.mid ){
        this.updatePromos();
       }else{
         console.log('Promotions Criteria failing here!!!')

       }
    }

    updatePromos=()=>{
      var url =Url+'/getpromos';
      var promo = this.state;
      promo.mid = this.props.mid;
      if(this.props.mid<=0){
        console.log('promo: mid not set!!!')
      //  ShowMsg('Please Select or Define Merchant First!!',false);
        return;
      }
      console.log('Doing merch promotions read POST here!!! mid: '+this.props.mid);
      Axios.post(url,promo).then((res)=>{
        console.log('promotion Response received: '+res.data.msg);
        if(res.data.msg==='ok'){
            console.log('Promo data received OK!!');
            if(res.data.promo===null){
            this.clearConfigs();
            }else{
              this.setState(res.data.promo);
            }
        }else{
          console.log('Failed to retrieve promotion data!!!')
        this.clearConfigs();
        }
      });
    }

    promo1Handler=(e)=>{
      this.setState({promo1:e.target.value})
    }
    promo2Handler=(e)=>{
      this.setState({promo2:e.target.value})
    }
    promo3Handler=(e)=>{
      this.setState({promo3:e.target.value})
    }
    promo4Handler=(e)=>{
      this.setState({promo4:e.target.value})
    }
    promo5Handler=(e)=>{
      this.setState({promo5:e.target.value})
    }

    isvalidatedOk =()=>{
    if(this.state.promo1.length===0)
      return false;
      return true;
    }


    clearConfigs=()=>{
      this.setState({
        promo1:'',
        promo2:'',
        promo3:'',
        promo4:'',
        promo5:'',

        });
    }



    saveHandler =()=>{
      if(this.props.mid<=0){
        ShowMsg('Please Select or Define Merchant First!!',false);
        return;
      }
      if(!this.isvalidatedOk()){
        //todo add msgbox
        ShowMsg('Please fill at least the first field!!',false);
        return;
      }
      var url =Url+'/addpromo';
      var promo = this.state;
      promo.mid = this.props.mid;
      if(this.props.pid>0){
        promo.id = this.props.pid;
      }
      console.log('Doing merch promo read POST here!!! mid: '+this.props.mid);
      Axios.post(url,promo).then((res)=>{
        console.log('Promo Response received: '+res.data.msg);
        if(res.data.msg==='ok'){
            console.log('Promo setting saved OK!!');
          ShowMsg('Promotion Data Saved Successfully!!',true);
        }else{
          console.log('Failed to save promo data!!!')
          ShowMsg('Failed to save Promotion Data!!!',false);
        }
      });
    }

    render(){
     return(
       <Card>
       <Card.Body>
       <Form>
       <CardColumns>
       <Card border='info'>
       <Card.Header variant='info'><h6>Promo Settings</h6></Card.Header>
       <Card.Body>
       <Form.Group>
       <Form.Label>Promo1</Form.Label>
       <Form.Control type="text" placeholder="" value={this.state.promo1} onChange={this.promo1Handler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Promo2</Form.Label>
       <Form.Control type="text" placeholder="" value={this.state.promo2} onChange={this.promo2Handler}/>
       </Form.Group>

       </Card.Body>
       </Card>
       <Card border='info'>
       <Card.Header variant='info'><h6>Promo Settings</h6></Card.Header>
       <Card.Body>
       <Form.Group>
       <Form.Label>Promo3</Form.Label>
       <Form.Control type="text" placeholder="" value={this.state.promo3} onChange={this.promo3Handler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Promo4</Form.Label>
       <Form.Control type="text" placeholder="" value={this.state.promo4} onChange={this.promo4Handler}/>
       </Form.Group>
       </Card.Body>
       </Card>
       <Card border='info'>
       <Card.Header variant='info'><h6>Promo Settings</h6></Card.Header>
       <Card.Body>
       <Form.Group>
       <Form.Label>Promo5</Form.Label>
       <Form.Control type="text" placeholder="" value={this.state.promo5} onChange={this.promo5Handler}/>
       </Form.Group>

       </Card.Body>
       </Card>
       </CardColumns>
       </Form>
       <Button variant="info" size="sm" className='mt-2' onClick={this.saveHandler} >SAVE SETTINGS</Button>{' '}
       </Card.Body>
       </Card>
     )
   }

  }

  export default PromoConfigurations;
