export const MENU_TYPE ={
    ADMIN:  1,
    MERCHANTS: 2,
    TERMINALS: 3,
    SOFTWAREGRP: 4,
    FORCE_UPDATE: 5,
    REPORTS: 6,
    CARD_GROUPS: 7,
    SETTINGS: 8
}

