import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "flatpickr/dist/themes/material_green.css";
import 'react-tabs/style/react-tabs.css';
import LoginForm from './Login';
import RegForm from './signup';
import Dashboard from './components/dash';
import TestApp from './components/test';
import {BrowserRouter as Router, Route} from 'react-router-dom';
//import Route from 'react-router-dom/Route';
import ProtectedRoute from './components/protectedroute';
import {useState} from 'react';
function App() {
  const [isAuth, setIsAuth]= useState(false);
  //  console.log("isAuth Status: "+isAuth);
  return (

    <Router>
    <div >
    <Route path="/auth" exact strict render={
      ()=>{
        return (<LoginForm onLogin={(x)=>{setIsAuth(x)}}/>)
      }
    }/>
 <Route path="/signup" exact strict render={
      ()=>{
        return (<RegForm/>)
      }
    }/>
  <Route path="/merch" exact strict render={
      ()=>{
        return (<TestApp />)
      }
    }/>

      <ProtectedRoute path='/' component={Dashboard} isAuth={isAuth}/>


    </div>
    </Router>
  );
}

export default App;
