import React from 'react';
class MenuButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      value:null,
    }
  }
  render(){
    return(
      <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
    <button className="btn btn-sm btn-outline-info btn-block"   onClick={()=>{this.props.runCode()}}>  {this.props.value}</button>
    </h6>
  );
  }
}
class RadioButton extends React.Component{
  constructor(props){
    super(props);

  }
  render(){
    return(
      <div className="form-check mx-sm-3 mb-2 form-check">
      <input className="form-check-input" type="radio" name="rad" value={this.props.value} checked={this.props.checked} />
        <label className="form-check-label" for="nsig">
          {this.props.label}
        </label>
      </div>
  );
  }
}

class SignOutButton extends React.Component{


  render(){
    return(
    <button className="btn btn-sm btn-outline-info" onClick={()=>{this.props.runCode()}}>Sign out</button>
  );
  }
}


class Select extends React.Component{
  constructor(props){
    super(props);
  this.state ={
    branches:[],
  }
  }


 render(){
   return(
   <select name={this.props.name} onChange={this.props.onChange} className="form-control" >
   <option selected value="">Select Shop Branch</option>
   {
     this.props.dlist.map((opt)=>(
       <option value={opt.value}>{opt.text}</option>
     ))
   }
   </select>

)
 }
}
export {RadioButton,MenuButton,Select,SignOutButton};
